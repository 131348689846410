<template>
  <div>
    <Permission/>
    <Breadcrumb/>
    <div class="title pb-4">New Property</div>
    <Form
      :fields="fieldsData"
      @onSave="createProperty"
      @newAddress="newAddress"
      @onAddressDeletion="onAddressDeletion"
    >
      <template v-slot:bottom v-if="isLoading">
        <b-button type="is-blue" class="mt-3" disabled>Save</b-button>
      </template>
    </Form>
  </div>
</template>
<script>
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import Permission from '@views/addy_plus/base/Permission.vue'
import Form from './Form.vue'
import { createProperty as createProp } from '@api/addy-plus/properties'
import { camelToSnakeCase } from '@utils/common-methods/common'
import { defaultAddressObject } from '@utils/data/addy_plus_address_fields.js'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'

export default {
  title: 'New Property',
  mixins: [pageTitleMixin],
  components: {
    Permission,
    Form,
    Breadcrumb,
  },
  data() {
    return {
      fieldsData: {},
      isLoading: false,
    }
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
  },
  mounted() {
    this.newAddress()
  },
  methods: {
    createProperty(params) {
      this.isLoading = true
      const payload = { ...this.parseParams(params), corporation_id: this.corporationId, sale_status: 'hidden' }
      createProp(payload).then((res) => {
        if (!res.success) return
        this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/properties`)
      }).finally(() => {
        this.isLoading = false
      })
    },
    parseParams(payload) {
      const obj = {}
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          obj[camelToSnakeCase(key)] = key === 'addresses' ? this.parseAddresses(payload[key]) : payload[key]
        }
      }
      return obj
    },
    parseAddresses(payload) {
      return payload.map((address) => this.parseParams(address))
    },
    newAddress() {
      if (!this.fieldsData.addresses) {
        this.$set(this.fieldsData, 'addresses', [])
      }
      const newAddressObject = JSON.parse(JSON.stringify(defaultAddressObject))
      this.fieldsData.addresses.push(newAddressObject)
    },
    onAddressDeletion(index) {
      const addresses = this.fieldsData.addresses
      addresses.splice(index, 1)
    },
  },
}
</script>
