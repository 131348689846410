













import Vue from 'vue'
// @ts-ignore
import Teleport from 'vue2-teleport'
import { mapState } from 'vuex'
import { IComputed, IProps } from '@/types/addy_plus/base/Permission'

export default Vue.extend<unknown, unknown, IComputed, IProps>({
  components: {
    Teleport,
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    crud() {
      const module: string = this.$route.meta.permissions
      if (!module) return []

      return this.permissions[module] || []
    },
    finalizedCrud() {
      return this.overwrite || this.crud
    },
    acronyms() {
      return this.finalizedCrud.map((item) => item[0])
    },
    title() {
      return this.finalizedCrud.length > 1 ? 'Permissions' : 'Permission'
    },
  },
  props: {
    overwrite: {
      type: Array
    },
  },
})
